main.activation {
  max-width: 1000px;
  width: 1000px;
}

.yd-frm-newcustomer {
  margin-left: 1.4rem;
}

.yd-activation {
  max-width:1040px;
  margin-left: auto;
  margin-right: auto;
}

.yd-activation input[type="text"],
.yd-activation input[type="password"],
.yd-activation textarea {
  background-color:#fff;
}

.yd-activation--header,
.yd-activation-steps li,
.yd-activation legend,
.yd-prevnext-nav {
  font-family:'Produkt',sans-serif;
}

.yd-activation-steps {
  list-style: none;
  position: relative;
  padding: 0;
  display: inline-block;
}

.yd-activation-steps li {
  display: inline-block;
  margin-right: .15rem;
  text-align: center;
}

.yd-activation--header {
  font-size:2.4rem;
  color: var(--btn-cta);
  padding-left:1.4rem;
}
.yd-activation select {
  width:100%;
  height:37px;
}
.yd-activation legend {
  font-size: 1.2rem;
  margin: 1.4rem 0;
  clear: both;
}

.yd-activation textarea {
  height:150px;
}

.yd-activation-steps {
  overflow: hidden;
  list-style-position: inside;
  margin-left:0;
  padding-left: 1.4rem;
}

.yd-activation-steps .yd-activation {
  padding-right: 1.8rem;
  border-bottom:3px solid #666;
  padding-bottom:.4rem;
}

.yd-activation-steps .yd-activation:last-child {
  padding-right:0;
}

.yd-activation a {
  color:#666;
}


.yd-prevnext-nav {
  overflow: hidden;
  margin-bottom:1.4rem;
  clear: both;
  margin-left:1.4rem;
  margin-top:2.4rem;
  background-color:#0080fc;
}

.yd-prevnext-nav > *:focus {
  outline:none;
}

.yd-prevnext-nav a {
  color:#fff;
  display: block;
  width:50%;
}

.yd-legend-top {
  padding-top: 4rem;
}

.yd-prevnext-prev {
  float: left;
  font-family: "Produkt", sans-serif;
  margin-left:15px;
  padding-right: 15px;
  margin-top:.5rem;
  padding-left: 15px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color:#fff;
}

.yd-prevnext-next {
    background-color: #fff;
    float: right;
    text-align: right;
    padding-right: 1.1rem;
    font-family: "Produkt", sans-serif;
    margin-right: 15px;
    margin-top:.5rem;
    padding-left: 15px;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.yd-activation-steps .yd-activation {
  margin-bottom: .8rem;
}

.yd-activation-steps .button {
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 13px;
  min-width: 150px;
  display: block;
  margin-bottom: 0;
  margin-right: 0;
  border-bottom: 3px solid var(--basic-lightest-gray);
}

.yd-activation-steps .yd-activation-selected .button {
  border-bottom: 3px solid #007ffc;
  background-color:#fff;
}

.yd-frm-newcustomer .input--error-state {
  border:1px solid #e6350f;
}

.yd-element-container, .yd-element-group {
  position: relative;
  overflow: visible;
}

.yd-element-group:after {
  clear: both;
}

.yd-element-group-divider {
  border: none;
  height: 1px;
  background-color: var(--basic-light-gray);
  width: calc(86% + 1rem);
  margin: 1rem 0;
}

.error--message {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
  background-color:#fff;
  position: absolute;
  top: 75px;
  left: 0;
  color:#e6350f;
  z-index: 999;
}

.error--message:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 12px;
  margin-left: -12px;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
}

.error--message-right {
  top: 0;
  left: auto;
  right: 0;
}

.error--message-right:after {
  bottom: auto;
  left: -12px;
  top: 8px;
  transform: rotate(270deg);
}

.yd-activation .yd-element-container-three {
  position: relative;
}

.yd-activation .button-trash-can {
  position: absolute;
  top: 2.25rem;
  left: 90%;
}

.yd-add-element-group {
  font-family:'Produkt',sans-serif;
  font-size:1rem;
  color:#0080fc;
  clear: both;
  cursor: pointer;
}

.yd-activation-success {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.yd-activation-success-header {
  font-family: Produkt,sans-serif;
  font-size: 1.4rem;
  margin-top: 1rem;
}

.yd-activation-new-button {
  background: var(--btn-cta);
  color: var(--basic-white);
  margin-top: 3rem;
}

.yd-recaptcha {
  visibility: hidden;
  height: 0;
}

.yd-recaptcha--visible {
  visibility: visible;
  height: initial;
}

.yd-recaptcha > div > div {
  margin: 0 auto;
  margin-top: 1rem;
}

.yd-recaptcha iframe {
  min-height: 100px;
}

.yd-recaptcha--error > div > div {
  border: 1px solid var(--status-alert);
}

.yd-frm-section {
  position: relative;
}

.yd-frm-section-title-extra {
  margin-top: -1.2rem;
  margin-bottom: 1rem;
  color: var(--basic-mid-gray);
}

#important-field-container {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-top: -40px;
}

#important-field {
  display: inline-block;
}

@media screen and (min-width: 37.5em) {
  .yd-activation .yd-element-container {
    float:left;
    width: 43%;
    margin-right:1rem;
    margin-bottom:.5rem;
  }

  .yd-activation .yd-element-container-two {
    float:left;
    width: 43%;
    margin-right:1rem;
    margin-bottom:.5rem;
  }

  .yd-activation .yd-element-container-three {
    float:left;
    width: 28%;
    margin-right:1rem;
    margin-bottom:.5rem;
  }

  .yd-element-top {
    padding-top: 1.2rem;
  }

  .yd-element-small-padding {
    padding-top:.6rem;
  }

  .yd-element-container-second {
    float:left;
    clear:left;
    width: 40%;
    margin-right:1rem;
    padding-top:.6rem;
  }

  .yd-activation label + input[type="text"] {
  }

  .yd-activation-steps .yd-activation {
    float:left;
    padding-bottom:.8rem;
  }

  .yd-prevnext-nav {
  }

  .button-container {
  }

  .yd-infotext {
    display: inline-block;
    margin-top: 1.8rem;
    min-height: 3rem;
    color: var(--basic-mid-gray);
  }
}
