.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;
}

.modal {
  background-color: var(--basic-white);
  left: 40px;
  margin: 0 auto;
  max-width: 980px;
  position: absolute;
  right: 40px;
  top: 100px;
}

.modal:focus {
  outline: none;
}

.modal-header {
  color: #fff;
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid #999;
  color:#0053a4;

}

.modal-content {
  padding: 2rem;
}

.modal-close {
  position: absolute;
  right: 0;
  top: 0.5rem;
  color: #0080F8;
  margin: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
