/* Styles for forms and styled form components */

/*
- Buttons are styled in a separate file
- React-Select should always be used instead of native <select> and
  it is styled in a separate file
*/

form label {
  display: block;
  margin-bottom: .5rem;
}

form select,
form input {
  display: block;
  font-family: Arial, sans-serif;
}

form select,
form input,
form select,
form textarea {
  font-family: Arial, sans-serif;
}

input[type="text"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--basic-mid-gray);
  box-shadow: none;
  display: block;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0;
  padding: 7px;
  width: 100%;
}

input {
  outline: none;
}

.checkbox-button {
  background-color: var(--basic-lightest-gray);
  color: #000;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 8px;
  min-width: 50px;
  overflow: auto;
  padding: 5px;
  user-select: none;
}

.checkbox-button--small {
  padding: 0 5px;
}

.checkbox-button:hover {}

.checkbox-button label {
  margin-bottom: 0;
  position: relative;
}

.checkbox-button label span {
  cursor: pointer;
  display: inline-block;
  padding: .3rem 0;
  text-align: center;
}

.checkbox-button label input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox-button input:checked + span {
  background-color: var(--basic-gray);
  color: #fff;
}

.checkbox-button input:focus + span {
  outline: 5px auto rgb(77, 144, 254);
}
