
.list-accordion-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: .15rem 0;
  position: relative;
}

.list-accordion-group__closed .list-accordion-group-content {
  display: none;
}

.list-accordion-group-content {
  width: 100%;
}

.list-accordion-group-datetime {
  position: absolute;
  left: 0;
  top: 0;
  width: 110px;
  padding-top: .5rem;
  color: #999;
  text-align: right;
  font-size: 1.1rem;
}

.list-accordion-group-title {
  background-color: var(--basic-lightest-gray);
  font-family: 'Produkt', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem 0;
  position: relative;
  width: calc(100% - 125px);
  cursor: pointer;
  margin-left: 125px;
}

.list-accordion-group-title-text {
  font-family: 'Produkt',sans-serif;
  display: inline-block;
  vertical-align: middle;
  padding-left: 1rem;
  width: calc(100% - 75px);
}

.list-accordion-group-title-extra {
  font-family: "IStok Web", Arial, sans-serif;
  font-weight: normal;
  display: block;
}

.list-accordion-group-status {
  position: absolute;
  top: 0;
  bottom: 0;
}

.list-accordion-group-status__warning {
  border-left: 7px solid var(--status-warning);
}

.list-accordion-group-status__error,
.list-accordion-group-status__alert {
  border-left: 7px solid var(--status-alert);
}

.list-accordion-group-status__normal,
.list-accordion-group-status__ok {
  border-left: 7px solid var(--status-success);
}

.list-accordion-group-status__offline {
  border-left: 7px solid var(--status-offline);
}

.list-accordion-group-toggle {
  color: var(--basic-mid-gray);
  background-color: transparent;
  position: absolute;
  right:0.75rem;
  top: 1rem;
  width: 2rem;
  height: 1.55rem;
}

.list-accordion-group__closed .list-accordion-group-toggle {
  color: var(--basic-blue);
}

.list-accordion-group-toggle > .svg-inline--fa {
  vertical-align: middle;
  width: 100%;
  font-size: 1.25rem;
  margin: 0.2rem auto;
}
