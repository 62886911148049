/* All application global font and text styles */


/* Text modifiers */

.capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}


/* Other */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Produkt", Arial, sans-serif;
  font-weight: normal;
}
h1,
h2 {
  font-family: "Produkt", Arial, sans-serif;
}


h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.4rem;
}

em,
h1 > em,
h2 > em,
h3 > em,
h4 > em,
h5 > em,
h6 > em {
  font-style: normal;
  font-family: 'IStok Web', Arial, sans-serif;
}

a {
  text-decoration: none;
}

main > h1 {
  border-bottom: 1px solid #999;
  margin-top: 0;
}

.hgroup {
  margin: 1rem 0;
}

.hgroup h1,
.hgroup h2,
.hgroup h3 {
  margin: 0 0 6px;
}


/* Inline font modifiers */

.link-text {
  color: var(--btn-cta);
}

.error-text {
  color: var(--status-alert);
}

.highlight-text {
  color: var(--btn-cta);
  font-weight: bold;
}
