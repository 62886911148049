.accordiongroup-button {
  color:#0080fc;
  text-align: right;
  cursor: pointer;
  float: right;
  font-size: 0.9rem;
  padding: 0.3rem 0;
}

.accordiongroup h3 {
  font-size: 1.2rem;
  font-family: "Produkt", sans-serif;
  color:#7a7a7a;
  padding-bottom:.5rem;
}
