.yd-timeframe-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.yd-timeframe-container > *:not(:last-child) {
  margin-right: 0.5rem;
}

.yd-timeframe-selection, .yd-timeframe-date-picker {
  min-width: 190px;
}

.yd-timeframe-date-picker {
  min-width: 120px;
}

.yd-timeframe-selection-title, .yd-timeframe-date-picker-title {
  font-size: small;
}

.yd-timeframe-date-picker input {
  height: 40px;
  width: 120px;
  padding: 0 10px;
  font-family: "IStok Web", Arial, sans-serif;
  font-size: 1rem;
  color: var(--basic-darkest-gray);
}

.yd-timeframe-date-picker .DayPickerInput-Overlay {
  left: -90px;
}

.yd-timeframe-select-reset {
  margin-right: 200px;
}

.yd-timeframe-select-reset-text {
  color: #0080fc;
  font-size: 0.9rem;
}

/*mobile*/
@media screen and (max-width:46.9em) {
  .yd-timeframe-container {
    display: block;
  }

  .yd-timeframe-selection {
    max-width: 100%;
    width: 248px;
  }

  .yd-timeframe-date-picker {
    display: inline-block;
    margin-top: 0.5rem;
  }
}
