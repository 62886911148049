.yd-toolbar-row {
  position: relative;
  z-index: 95;
}

.yd-toolbar {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  position: relative;
  z-index: 100;
  padding: 0 1rem;
  border-bottom: 1px solid var(--basic-light-gray);
}

.yd-toolbar-title-container {
  flex: 1;
  min-width: 200px;
}

.yd-toolbar-title-container h3 {
  max-width: calc(100% - 70px);
  display: inline-block;
  font-family:"Produkt", sans-serif;
  vertical-align: middle;
}

.yd-toolbar-title-container .yd-toolbar-title-details {
  font-size: small;
  font-family:Arial,sans-serif;
  display: block;
}

.sc-toolbar-select-container {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}

.sc-toolbar-select-container .sc-toolbar-select-icon {
  padding: 10px;
}

.sc-toolbar-select-container .sc-toolbar-select-filter {
  width: calc(100% - 36px);
  max-width: 320px;
  min-width: 200px;
}

.yd-toolbar-icon {
  margin-right: 1rem;
  margin-left: -40px;
}

.yd-toolbar-link {
  display: inline-block;
  margin-right: 1rem;
  border-right: 1px solid var(--basic-light-gray);
  background: transparent;
}
