.kc-event {
  display: flex;
  margin-bottom: 1.5rem;
}

.kc-event-date {
  color: var(--basic-mid-gray);
  font-size: 1.4rem;
  padding: 1rem;
  width: 20%;
  min-width: 150px;
}

.kc-event-content {
  flex: 1 1 auto;
}

.kc-event-header {
  display: flex;
  padding-bottom: 1rem;
}

.kc-event-icon {
  margin-right: .5rem;
  padding: .5rem;
}

.kc-event .kc-event-symbol {
  width: 48px;
  height: 48px;
  margin-right: 1rem;
}

.kc-event-header small {
  display: block;
  font-size: 1rem;
}

.kc-event-header h1 {
  font-size: 1.6rem;
  font-weight: normal;
  margin: 0;
}

.kc-event-meta {
  border-top: 1px solid var(--basic-mid-gray);
}
