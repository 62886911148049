:root {
  --status-alert: #e6350f;
  --status-warning: #ffbc00;
  --status-success: #72c90c;
  --status-production-risk: #ff9900;
  --status-offline: #999;
  --status-improvement-opportunity: #333399;
  --service-color-alpha: #f7f7f7;
  --service-color-beta: #26e2e6;
  --service-color-gamma: #0b5454;
  --service-color-delta: #486280;
  --service-color-epsilon: #dcdcdc;
  --service-color-zeta: #a6becc;
  --service-color-eta: #6597aa;
  --button-thickness: 4px;
  --basic-darkest-gray: #1a1a1a;
  --basic-gray: #333;
  --basic-mid-gray: #999;
  --basic-light-gray: #ccc;
  --basic-lightest-gray: #f6f6f6;
  --basic-white: #fff;
  --basic-blue:#0080F8;
  --btn-cta: #0053a7;
  --btn-success: #72c90c;
  --btn-disabled: #999;
  --btn-fail: #f71c1c;
  --header-height: 130px;
  --header-tablet-height: 140px;
  --header-mobile-height: 130px;
}

@import url("https://fonts.googleapis.com/css?family=Istok+Web:400,400i,700,700i");


@font-face {
  font-family: 'Produkt_orig';
  src: url("/assets/fonts/Produkt-Semibold-Web.eot");
  src: url("/assets/fonts/Produkt-Semibold-Web.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Produkt-Semibold-Web.woff2") format("woff2"), url("/assets/fonts/Produkt-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Produkt';
  src: url("/assets/fonts/hinted-Produkt-Semibold.eot");
  src: url("/assets/fonts/hinted-Produkt-Semibold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/hinted-Produkt-Semibold.woff2") format("woff2"), url("/assets/fonts/hinted-Produkt-Semibold.woff") format("woff"), url("/assets/fonts/hinted-Produkt-Semibold.ttf") format("truetype"), url("/assets/fontshinted-Produkt-Semibold.svg#Produkt-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Produkt Web';
  src: url("/assets/fonts/Produkt-Semibold-Web.eot");
  src: url("/assets/fonts/Produkt-Semibold-Web.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Produkt-Semibold-Web.woff2") format("woff2"), url("/assets/fonts/Produkt-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'iconfont';
  src: url("/assets/fonts/iconfont.eot?s4hiw6");
  src: url("/assets/fonts/iconfont.eot?s4hiw6#iefix") format("embedded-opentype"), url("/assets/fonts/iconfont.ttf?s4hiw6") format("truetype"), url("/assets/fonts/iconfont.woff?s4hiw6") format("woff"), url("/assets/fonts/iconfont.svg?s4hiw6#iconfont") format("svg");
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
}

body {
  color: var(--basic-darkest-gray);
  font-family: 'IStok Web', Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.border-bottom {
  border-bottom: 1px solid var(--basic-gray);
  margin-bottom: 1rem;
}

.kc-subheader--border {
  border-bottom: 1px solid #000;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix {
  clear: both;
}

.clearfix:after {
  clear: both;
  content: '';
  display: table;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
