/* Application main layout classes */

.block {
  display: block;
}

main {
  max-width: 60em;
  width: 60em;
  position: relative;
  margin: 0 auto;
}

/*tablet and mobile*/
@media screen and (max-width:59.99em) {
  main {
    width: 99%;
    max-width: 99%;
  }
}

/* Spacings */

/* Uncomment and more when needed */

/*.margin-bottom    {margin-bottom: 1rem}*/
/*.margin-bottom-l  {margin-bottom: 2rem}*/
.margin-bottom-xl {margin-bottom: 4rem}

.no-margin-bottom {margin-bottom: 0}
/*.no-margin-left   {margin-left: 0}*/
/*.no-margin-right  {margin-right: 0}*/
/*.no-margin-top    {margin-top: 0}*/


/* Flex classes */

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
}

.flex-row .flex-row {
  margin: 0 -15px;
  max-width: calc(100% + 30px);
  width: calc(100% + 30px);
}

@media (max-width: 1750px) {
  .flex-row.with-sidebar {
    margin: 0 0 0 230px;
    max-width: calc(100% - 230px);
  }
}

@media (max-width: 1024px) {
  .flex-row.with-sidebar {
    margin: 0;
    max-width: 100%;
  }
}

.flex-row > * {
  flex: auto;
  padding: 0 15px;
}

@media (max-width: 1024px) {
  .flex-row {
    flex-direction: column;
  }
}

@media (min-width: 1025px) {
  .cols-2 {
    width: 50%;
  }
}
