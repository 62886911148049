.highlight {
  color:#233338;
}

.yd-info {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.flex-four-cols {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-four-cols .flex-item {
  flex: 1 1 22%;
  margin-right: 20px;
}

.flex-four-cols svg {
  margin-left: 50px;
  margin-top: 20px;
}

.flex-item-bg {
  background-color: #f2f2f2;
}

.flex-item-actions {
  display: flex;
  flex-direction: row;
}

.flex-item-actions .flex-item {
  flex: 1 1 48%;
  margin-right:0;
}
