
.yd-alertlist {
  list-style: none;
}

.yd-info-header {
  border-bottom: 1px solid #999;
  color:#0053a4;
}

.yd-shortseparator {
  margin: 18px auto;
  width:20%;
  border:1px solid #b0b0b0;
}

.yd-alert-diagnostic {
  font-family: "IStok Web", Arial, sans-serif;
  font-weight: normal;
  display: flex;
}

.yd-alert-diagnostic-title {
  font-family: Produkt, sans-serif;
  width: 120px;
  padding-top: 0.25rem;
}

.yd-alert-diagnostic-description {
  margin-left: 2rem;
}

.yd-alert-filters {
  margin-top: 1rem;
}

.yd-alert-filter-label {
  display: inline-block;
  padding: .5rem;
  margin-right: .5rem;
  margin-bottom: .5rem;
  font-size: small;
  cursor: pointer;
}

.yd-alert-filter-label--selected {
  background-color: #0080F8;
  color: var(--basic-white);
}

.yd-alert-filter-label--error svg {
  color: var(--status-alert);
}

.yd-alert-filter-label--warning svg {
  color: var(--status-warning);
}


.alert-details-row {
  display: flex;
  flex-direction: row;
  margin-left: 2.5em;
  margin-bottom: 0.25rem;
  /*margin-top: 0.25rem;*/
}

.alert-details-header {
/*  margin: .25rem 0;*/
  position: relative;
  border: 1;
  flex-grow: 1;
  background-color: var(--basic-lightest-gray);
  display: flex;
}

.alert-details-time {
  left: 0;
  top: 0;
  width: 130px;
  padding: 0.5em;
  color: #999;
  text-align: right;
  font-size: 1.1rem;
  border: 1
}

.alert-details-date-time {
  padding-top: .5rem;
  color: #999;
  text-align: right;
  font-size: 1.1rem;
  width: 130px;
  padding-right: 1rem;
}

.alert-details-title {
  display: block;
  padding: 16px 16px 9px 16px;
}

.alert-details-header-content {
  flex-grow: 100;
}

.status__warning {
  border-left: 7px solid var(--status-warning);
}

.status__error {
  border-left: 7px solid var(--status-alert);
}

.status__normal{
  border-left: 7px solid var(--status-success);
}


.list-accordion-content {
  padding: 1rem 0 0 1rem;
  width: 100%;
}

.alert-details-alert-type--error svg {
  color: var(--status-alert);
  padding-right: 0;
}

.alert-details-alert-type--warning svg {
  color: var(--status-warning);
}

.alert-details-alert-type--error svg {
  color: var(--status-alert);
}

.alert-details-alert-type span {
  float: right;
}

.alert-details-component {
  width: 100%;
}

/* Some overrides to enable component reuse in Alerts page */
.alert-details-alert-type {
  padding-right: 0 !important;
}

.list-accordion-group {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.list-accordion-group-title {
  margin-left: 146px !important;
  width: calc(100% - 146px) !important;
  /*margin-top: 0.25rem !important;*/
  margin-bottom: 0.25rem !important;
}

.alert-details-close {
  color: #fff;
  position: absolute;
  right: 0;
  top: 0.5rem;
  background-color: #0080F8;
  margin: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-level-details {
  margin-left: 1rem;
}

.top-level-details .alert-details-title {
  padding-left: 9px;
  padding-bottom: 16px;
}

.list-accordion-group-datetime {
  margin-left: 18px !important;
/*  margin-bottom: 0.25rem !important;*/
}
