.yd-statistics-header {
  display: flex;
  justify-content: space-between;
}

.yd-statistics-tabs {
  list-style: none;
  position: relative;
  padding: 0;
  display: inline-block;
  margin: 0;
}

.yd-statistics-tabs li {
  display: inline-block;
  margin-right: .15rem;
  text-align: center;
}

.yd-statistics-tabs .button {
  background: white;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 13px;
  min-width: 120px;
  display: block;
  margin-bottom: 0;
  margin-right: 0;
  color: #676f77;
}

.yd-statistics-tabs .yd-button--selected .button {
  border-bottom: 3px solid #007ffc;
  background-color:#fff;
}

.yd-statistics-timeframe {
  display: inline-block;
  max-width: 460px;
}

/*tablet and mobile*/
@media screen and (max-width:59.99em) {
  .yd-statistics-header {
    display: block;
  }

  .yd-statistics-timeframe {
    margin: 1em 0;
  }
}

/*mobile*/
@media screen and (max-width:46.9em) {
  .yd-statistics-tabs .button {
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 100px;
  }

  .yd-statistics-timeframe {
    display: block;
  }
}
