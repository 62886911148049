.button {
  background: var(--basic-lightest-gray);
  border: none;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 10px;
  margin-right: 8px;
  min-width: 50px;
  outline: none;
  padding: 10px;
  user-select: none;
  font-family: 'Produkt'
}

button:enabled,
.button:enabled {
  cursor: pointer;
}

.button--right {
  margin-left: 8px;
  margin-right: 0;
}

.button:focus {
  
}

.button--small {
  font-size: 0.875rem;
  padding: .35rem;
}

.button--fullwidth {
  display: block;
  margin: 0;
  position: relative;
  width: 100%;
}

.button--big {
  height: 60px;
  line-height: 40px;
}

.button--fullwidth .button-icon {
  position: absolute;
  right: 1rem;
  background-color:#0080fc;
}

.button--active,
.button--white.button--active {
  background: var(--basic-gray);
  color: #fff;
}

.button--alert {
  border-left: var(--button-thickness) solid var(--status-alert);
}

.button--warning {border-left: var(--button-thickness) solid var(--status-warning)}

.button--success {border-left: var(--button-thickness) solid var(--status-success)}

.button--cta {
  background-color: var(--btn-cta);
  color: #fff;
  padding: 10px;
}

.button--light {
  background-color:#0080fc;
  color:#fff;
}

.button--ghost {
  background: transparent;
  color: var(--btn-cta);
  padding: 0;
}

.button--ghost-light {
  background: transparent;
  color: var(--basic-blue);
  padding: 0;
}

.button--transparent {
  background: transparent;
}

/*.secondary-button--success,
.secondary-button--alert,
.secondary-button--warning,
.secondary-button--disabled,
.secondary-button--external,
.secondary-button--risk {
  padding: 10px;
}*/

.secondary-button--success,
.secondary-button--alert,
.secondary-button--warning,
.secondary-button--risk {
  color: #fff;
}

.secondary-button--alert {
  background-color: var(--status-alert);
}

.secondary-button--warning {
  background-color: var(--status-warning);
}

.secondary-button--success {
  background-color: var(--status-success);
}

.secondary-button--disabled {
  background-color: var(--basic-mid-gray);
  color: var(--basic-light-gray);
}

.secondary-button--external {
  border: 1px solid #000;
  background-color: transparent;
}

.secondary-button--external[disabled] {
  color: var(--basic-mid-gray);
}

.secondary-button--risk {
  background-color: var(--status-production-risk);
}

.button--white {
  background: #fff;
  color: var(--basic-gray);
}
